<template>
  <div :class="myProfile ? 'container' : ''">
    <el-page-header
      v-if="!myProfile"
      @back="$router.go(-1)"
      :content="title"
    ></el-page-header>
    <div class="box-card mt-3">
      <div class="titleStyle">
        <h1 class="primaryColor" style="font-size: 30px;">
          {{ title }}
        </h1>
        <el-divider></el-divider>
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane :label="$t('userProfile.basicInfo')" name="info">
            <Heading :content="$t('userProfile.basic.title')"></Heading>
            <hr />
            <el-form
              v-if="userInfo"
              ref="form"
              :model="userInfo"
              label-width="120px"
            >
              <el-form-item :label="'Name'">
                <b>{{ title }}</b>
              </el-form-item>
              <template v-if="!isCompany">
                <el-form-item :label="$t('userProfile.basic.email')">
                  <b v-if="userInfo.email_verified_at" class="text-success">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="
                        `${instant.formatGoogleTime(
                          userInfo.email_verified_at
                        )} verified`
                      "
                      placement="top"
                    >
                      <span>
                        {{ userInfo.email }}
                        <i class="el-icon-success"></i>
                      </span>
                    </el-tooltip>
                  </b>
                  <b v-else class="text-warning">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Pending"
                      placement="top"
                    >
                      <span>
                        {{ userInfo.email }}
                        <i class="el-icon-warning"></i>
                      </span>
                    </el-tooltip>
                  </b>
                </el-form-item>
                <el-form-item label="Plan" v-if="adminViewUser || myProfile">
                  <b
                    v-if="userInfo.all_roles.indexOf('pro') > -1"
                    style="color: orange;"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="
                        `${$t('plans.planDuration')}${moment(
                          this.userInfo.pro_expired_at
                        ).format('YYYY-MM-DD')} (GMT+8)`
                      "
                      placement="top"
                    >
                      <span class="proIcon">
                        <i class="fas fa-crown" />
                        Pro
                      </span>
                    </el-tooltip>
                  </b>
                  <b v-else>
                    Free
                    <router-link :to="{ name: 'Plans' }">
                      (Click to Upgrade)
                    </router-link>
                  </b>
                </el-form-item>
              </template>
              <el-form-item v-else :label="$t('userProfile.basic.email')">
                <b>
                  {{ userInfo.email }}
                </b>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('sat.SAT_Results')"
            v-if="!myProfile && testResults && testResults.length"
            name="results"
          >
            <Heading :content="$t('sat.SAT_Results')"></Heading>
            <hr />
            <template v-if="testResults && testResults.length > 0">
              <el-table :data="testResults">
                <el-table-column label="Test">
                  <template slot-scope="scope">
                    <router-link
                      :to="{
                        name: 'SATResult',
                        query: {
                          practiceType:
                            scope.row.exam.test_type ===
                            'Modules\\Practice\\Entities\\Practice'
                              ? 'singlePractice'
                              : 'composePractices'
                        },
                        params: { id: scope.row.id }
                      }"
                    >
                      {{
                        getCompanyExamTitle(scope.row.exam.title)
                      }}</router-link
                    >
                  </template>
                </el-table-column>
                <el-table-column label="" width="100">
                  <template slot-scope="scope">
                    <router-link
                      :to="{
                        name: 'SATResult',
                        query: {
                          practiceType:
                            scope.row.exam.test_type ===
                            'Modules\\Practice\\Entities\\Practice'
                              ? 'singlePractice'
                              : 'composePractices'
                        },
                        params: { id: scope.row.id }
                      }"
                    >
                      <el-tooltip
                        v-if="
                          scope.row.exam.category === 11 ||
                            scope.row.exam.category === 12
                        "
                        effect="dark"
                        content="Correct/Total"
                        placement="top"
                      >
                        <b class="text-success">
                          {{ scope.row.total_score }}/{{
                            scope.row.exam.question_count
                          }}
                        </b>
                      </el-tooltip>
                      <el-tooltip
                        v-else
                        effect="dark"
                        content="Score"
                        placement="top"
                      >
                        <b class="text-success">
                          {{ scope.row.total_score }}
                        </b>
                      </el-tooltip>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column label="Status" width="120px">
                  <template slot-scope="scope">
                    <el-tag
                      :type="scope.row.is_finished === 0 ? 'danger' : 'success'"
                      disable-transitions
                    >
                      <span v-if="scope.row.is_finished === 0">
                        {{ $t("courses.Incomplete") }}
                      </span>
                      <span v-else>{{ $t("courses.completed") }}</span>
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="Actions" width="100">
                  <template slot-scope="scope">
                    <div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="View"
                        placement="top"
                      >
                        <router-link
                          :to="{
                            name: 'SATResult',
                            query: {
                              practiceType:
                                scope.row.exam.test_type ===
                                'Modules\\Practice\\Entities\\Practice'
                                  ? 'singlePractice'
                                  : 'composePractices'
                            },
                            params: { id: scope.row.id }
                          }"
                        >
                          <el-button type="success" size="small" plain>
                            <i class="fa fa-eye"></i>
                          </el-button>
                        </router-link>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Date" width="150">
                  <template slot-scope="scope">
                    {{ instant.formatGoogleTime(scope.row.created_at) }}
                  </template>
                </el-table-column>
              </el-table>
              <div class="text-center" style="margin:15px 0">
                <el-pagination
                  background
                  @current-change="testResultsSetPage"
                  :page-size="resultsPage.pageSize"
                  :current-page="resultsPage.page"
                  :total="resultsPage.total"
                  :page-count="6"
                  layout="prev, pager, next"
                >
                </el-pagination>
              </div>
            </template>
            <!-- <div v-else>
              <el-empty description="No Data"></el-empty>
            </div> -->
          </el-tab-pane>
          <el-tab-pane :label="$t('pageTitle.Performance')" name="performance">
            <Heading :content="$t('pageTitle.Performance')"></Heading>
            <div
              v-if="
                tag_analysis &&
                  tag_analysis.length > 0 &&
                  activeName === 'performance'
              "
            >
              <div
                v-for="(tag_analysis, key, index) in tag_analysis_arr"
                :key="index"
              >
                <hr />
                <Performance
                  v-if="tag_analysis && tag_analysis.length > 0"
                  section="Reading"
                  :tagType="key"
                  :tags="tag_analysis"
                  :activeName="activeName"
                ></Performance>
              </div>
            </div>
            <div v-else>
              <hr />
              <el-empty description="No Data"></el-empty>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('TeacherCourses.My Classes')"
            name="classes"
            v-if="!teacherViewUser"
          >
            <Heading content="Classes"></Heading>
            <hr />
            <template v-if="studentClasses && studentClasses.length > 0">
              <Classes
                :classes="studentClasses"
                :resultsTotal="studentPage.total"
                :pageSize="studentPage.pageSize"
                :routerName="
                  adminViewUser ? 'AdminCourseDetail' : 'StudentCourseDetail'
                "
              ></Classes>
              <div class="text-center mb-3">
                <el-pagination
                  background
                  @current-change="studentSetPage"
                  :page-size="studentPage.pageSize"
                  :current-page="studentPage.page"
                  :total="studentPage.total"
                  :pager-count="9"
                  layout="prev, pager, next"
                >
                </el-pagination>
              </div>
            </template>
            <div v-else>
              <el-empty description="No Data"></el-empty>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('TeacherCourses.Classes I Created')"
            v-if="!myProfile && !teacherViewUser && teacherClasses.length > 0"
            name="classesCreated"
          >
            <Heading content="Classes Created"></Heading>
            <hr />
            <Classes
              :classes="teacherClasses"
              :resultsTotal="teacherPage.total"
              :pageSize="teacherPage.pageSize"
              routerName="AdminCourseDetail"
            ></Classes>
            <div class="text-center mb-3">
              <div class="text-center mb-3">
                <el-pagination
                  background
                  @current-change="teacherSetPage"
                  :page-size="teacherPage.pageSize"
                  :current-page="teacherPage.page"
                  :total="teacherPage.total"
                  :pager-count="9"
                  layout="prev, pager, next"
                >
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="!teacherViewUser && theme.name === 'SAT'"
            :label="$t('pageTitle.Balance')"
            name="balance"
          >
            <Heading :content="$t('pageTitle.Balance')"></Heading>
            <hr />
            <InviteCard
              v-if="
                myProfile ||
                  (userProfile &&
                    userProfile.referral_codes &&
                    userProfile.referral_codes.length > 0)
              "
              :getProfile="userProfile"
              @viewInvitation="viewInvitation"
            ></InviteCard>
            <Balance :point="userInfo.coin" />
            <Trades v-if="myProfile" />
            <UserTransactions :userId="userInfo.id" />
          </el-tab-pane>
          <el-tab-pane
            :label="$t('pageTitle.Invitation')"
            v-if="
              userProfile &&
                userProfile.referral_codes &&
                userProfile.referral_codes.length > 0 &&
                theme.name === 'SAT'
            "
            name="invitation"
          >
            <Heading :content="$t('pageTitle.Invitation')"></Heading>
            <hr />
            <h5>
              {{
                $t(
                  "sat.Invite friends to use SATMocks and win unlimited free Coins!"
                )
              }}
            </h5>
            <p>
              {{ $t("sat.Earn 10 Coins") }}
            </p>

            <el-form class="mt-4" label-width="150px">
              <el-form-item label="Your invite link">
                <b slot="label">
                  {{ $t("toefl.Invitation Link") }}
                </b>
                <div class="referral-code mb-2">
                  <a>
                    <span>{{ link }}</span>
                  </a>
                  <el-button type="success" @click="copyLink">
                    {{ $t("toefl.Copy Link") }}
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="Total Coins earned">
                <b slot="label">
                  {{ $t("sat.balance.Total Coins earned") }}
                </b>
                <b class="text-success">
                  {{ Math.floor(userCorrect / 2) * 10 }} Coins</b
                >
              </el-form-item>
              <el-form-item label="Details">
                <b slot="label">
                  {{ $t("sat.balance.Details") }}
                </b>
                <el-table
                  :data="userProfile.referral_codes[0].usages"
                  height="500"
                  border
                >
                  <el-table-column label="Email">
                    <template slot-scope="scope">
                      <b>{{ scope.row.email }}</b>
                    </template>
                  </el-table-column>
                  <el-table-column label="Date">
                    <template slot-scope="scope">
                      {{ instant.formatGoogleTime(scope.row.updated_at) }}
                    </template>
                  </el-table-column>
                  <el-table-column label="Status">
                    <template slot-scope="scope">
                      <template v-if="scope.row.pivot.valid_ip !== 0">
                        <b
                          v-if="scope.row.email_verified_at"
                          class="text-success"
                        >
                          <i class="el-icon-success"></i>
                          {{ $t("sat.balance.Completed") }}
                        </b>
                        <el-tooltip
                          v-else
                          class="item"
                          effect="dark"
                          :content="
                            $t(
                              'sat.balance.Waiting for the user to register and verify email'
                            )
                          "
                          placement="top"
                        >
                          <b class="text-warning">
                            <i class="el-icon-question"></i>
                            {{ $t("sat.balance.Pending") }}
                          </b>
                        </el-tooltip>
                      </template>
                      <template v-else>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="$t('sat.balance.The invitee')"
                          placement="top"
                        >
                          <b class="text-danger">
                            <i class="el-icon-question"></i>
                            {{ $t("sat.balance.Invalid") }}
                          </b>
                        </el-tooltip>
                      </template>
                      <!-- <el-tooltip
                        class="item"
                        effect="dark"
                        content="The invitee isn't a valid user. "
                        placement="top"
                      >
                        <b class="text-danger">
                          <i class="el-icon-question"></i>
                          Invalid
                        </b>
                      </el-tooltip> -->
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import { instant, user } from "@ivy-way/material";
import userApi from "@/apis/user";
import SAT from "@/apis/sat";
import Performance from "@/views/performance/performance/Index.vue";
import InviteCard from "@/components/balance/InviteCard.vue";
import Balance from "@/components/balance/Balance.vue";
import Trades from "@/components/balance/Trades.vue";
import Classes from "@/views/profile/components/Classes.vue";
import UserTransactions from "@/components/balance/UserTransactions.vue";
import baseDomainPath from "@/common/baseDomainPath";
import Theme from "@/common/theme";
import moment from "moment";

export default {
  metaInfo() {
    return {
      title: `${this.title} - ${this.CompanyName}`
    };
  },

  components: {
    Performance,
    InviteCard,
    Trades,
    Balance,
    UserTransactions,
    Classes
  },

  mixins: [],

  props: [],
  data() {
    return {
      moment,
      referral_code: null,
      referral: null,
      userCorrect: 0,
      userInfo: null,
      userProfile: null,
      tag_analysis: null,
      studentClasses: null,
      // studentResultsTotal: 0,
      // studentPageSize: 9,
      teacherClasses: null,
      // teacherResultsTotal: 0,
      // teacherPageSize: 9,
      testResults: null,
      activeName: "info",
      studentPage: {
        pageSize: 6,
        page: 1,
        total: 0
      },
      teacherPage: {
        pageSize: 6,
        page: 1,
        total: 0
      },
      resultsPage: {
        pageSize: 10,
        page: 1,
        total: 0
      }
    };
  },
  computed: {
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    theme() {
      return Theme;
    },
    link() {
      return `${baseDomainPath}register?referral_code=${this.referral_code}`;
    },
    tag_analysis_arr() {
      let res = null;
      if (this.tag_analysis) {
        res = _.groupBy(this.tag_analysis, "tag_type");
      }
      return res;
    },
    userId() {
      return this.$route.params.id ? this.$route.params.id : this.getProfile.id;
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    teacherViewUser() {
      return (
        this.$route.name == "TeacherViewUser" ||
        this.$route.name == "CompanyAdminViewUser"
      );
    },
    myProfile() {
      return this.$route.name === "UserProfile";
    },
    adminViewUser() {
      return this.$route.name == "AdminViewUser";
    },
    title() {
      if (this.userInfo) {
        return user.displayName(
          this.userInfo.first_name,
          this.userInfo.last_name
        );
      } else {
        return " - ";
      }
    }
  },
  watch: {
    userId(userId) {
      if (userId) {
        this.getUserProfile();
        this.getUserTagAnalysis();
        this.getUserClasses();
        if (!this.myProfile) {
          this.getUserResults();
          this.getTeacherClasses();
        }
      }
    }
  },

  mounted() {
    if (this.$route.name === "UserProfile") {
      this.activeName = "info";
    }
    if (this.$route.query.active) {
      this.activeName = this.$route.query.active;
    }
    if (this.userId) {
      this.getUserProfile();
      this.getUserTagAnalysis();
      this.getUserClasses();
      if (!this.myProfile) {
        this.getUserResults();
        this.getTeacherClasses();
      }
    }
  },

  methods: {
    viewInvitation() {
      this.activeName = "per";
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({
        message: this.$t("Profile.Copied"),
        type: "success"
      });
    },
    testResultsSetPage(page) {
      this.resultsPage.page = page;
      this.getUserResults();
    },
    studentSetPage(page) {
      this.studentPage.page = page;
      this.getUserClasses();
    },
    teacherSetPage(page) {
      this.teacherPage.page = page;
      this.getTeacherClasses();
    },
    async getUserProfile() {
      if (this.myProfile) {
        const res = this.getProfile;
        this.userInfo = res;
        this.userProfile = res;
        this.referral =
          this.userInfo.referral_codes &&
          this.userInfo.referral_codes.length > 0
            ? this.userInfo.referral_codes[0]
            : null;
        this.referral_code = this.referral.code;
        if (this.referral) {
          this.referral.usages.forEach(user => {
            if (user.email_verified_at && user.pivot.valid_ip !== 0) {
              this.userCorrect++;
            }
          });
        }
      } else {
        const res = await userApi.getUserProfileById(this.userId);
        this.userProfile = res.user;
        this.userInfo = res.user;
        this.referral =
          this.userInfo.referral_codes &&
          this.userInfo.referral_codes.length > 0
            ? this.userInfo.referral_codes[0]
            : null;
        this.referral_code = this.referral.code;
        if (this.referral) {
          this.referral.usages.forEach(user => {
            if (user.email_verified_at && user.pivot.valid_ip !== 0) {
              this.userCorrect++;
            }
          });
        }
      }
    },
    async getUserTagAnalysis() {
      const res = await SAT.getUserTagAnalysis(this.userId);
      this.tag_analysis = res ? res : null;
    },
    async getUserResults() {
      let role = "admin";
      if (this.teacherViewUser) {
        role = "teacher";
      }
      const res = await userApi.getUserResults(role, this.userId, {
        page: this.resultsPage.page,
        per_page: this.resultsPage.pageSize
      });
      this.testResults = res ? res.user_exams.data : null;
      this.resultsPage.total = res ? res.user_exams.total : null;
    },
    async getUserClasses() {
      const res = await userApi.getClasses({
        student_user_id: this.userId,
        per_page: this.studentPage.pageSize,
        page: this.studentPage.page
      });
      this.studentClasses = res.session_classes.data;
      this.studentPage.total = res.session_classes.total;
      // this.tag_analysis = res ? res : null;
    },
    async getTeacherClasses() {
      const res = await userApi.getClasses({
        teacher_user_id: this.userId,
        per_page: this.teacherPage.pageSize,
        page: this.teacherPage.page
      });
      this.teacherClasses = res.session_classes.data;
      this.teacherPage.total = res.session_classes.total;
      // this.tag_analysis = res ? res : null;
    }
  }
};
</script>

<style scoped>
.referral-code span {
  display: inline-block;
  border: 1px solid #ebeef5;
  padding: 0 20px;
  margin-right: 10px;
  font-size: 1rem;
  height: 42px;
  line-height: 42px;
}
.box-card {
  display: inline-block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.proIcon {
  display: inline-block;
  margin: 0px 2px;
  color: orange;
}
</style>
